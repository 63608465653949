import React from "react"
import styled from "@emotion/styled"

import InnerWrap from "../_Wrap/inner-wrap"
const Member = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  height: auto;
  max-width: 98%;

  margin-top: 6rem;
  margin-bottom: 6rem;
  && h6.name {
    color: var(--brz-blue);
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  && .creds {
    font-family: questa-slab, serif;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 1rem;
  }
  && .info {
    max-width: 90%;
  }
  && .details {
    margin-bottom: 1rem;
  }
  && .picture {
    width: 96%;
    img {
      width: inherit;
    }
  }
  @media all and (max-width: 768px) {
    max-width: 100%;
    grid-template-columns: 1fr;
    && h6.name {
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    && .details,
    .creds,
    .email {
      font-size: 1.1rem;
    }
  }
`

export const SingleTeamMember = ({
  name,
  picture,
  details,
  number,
  email,
  website,
  title,
  creds,
}) => {
  return number % 2 === 0 ? (
    <Member>
      <div className="picture d-md-none">
        <img src={picture} />
      </div>
      <div className="info">
        <h6 className="name"> {name} </h6>
        <h6 className="title"> {title}</h6>
        <div className="creds">{creds}</div>
        <div className="details">{details}</div>
        <div className="email">
          {email} | {website ? <a href={website}> {website}</a> : ""}
        </div>
      </div>
      <div className="picture d-none d-md-block ">
        <img src={picture} />
      </div>
    </Member>
  ) : (
    <Member>
      <div className="picture">
        <img src={picture} />
      </div>
      <div className="info">
        <h6 className="name"> {name} </h6>
        <h6 className="title"> {title}</h6>
        <div className="creds">{creds}</div>
        <div className="details">{details}</div>
        <div className="email">
          {email} | {website ? <a href={website}> {website}</a> : ""}
        </div>
      </div>
    </Member>
  )
}
