import React from "react"
import { graphql } from "gatsby"
import { Row, Container } from "react-bootstrap"

import { makeLink } from "../utils/google-pic-format"
import Hero from "../components/Hero"

import { SingleTeamMember } from "../components/AboutUs/SingleTeamMember"
import SEO from "../components/seo"
import CTA from "../components/CTA"
import InnerWrap from "../components/_Wrap/inner-wrap"

const AboutPage = ({ data }) => {
  let p = makeLink(data.Meta.edges[0].node.headerPicture)

  return (
    <>
      <SEO
        title="Team Brazee"
        keywords={[`brazee`, `cincinnati`, `art classes`, `corporate awards`]}
      />
      <Hero src={p}></Hero>
      <Container fluid>
        <Row>
          <InnerWrap>
            <h1 className="blue" style={{ marginTop: "6rem" }}>
              Team Brazee
            </h1>
          </InnerWrap>
        </Row>
        <Row>
          <InnerWrap>
            {data.AboutData.edges.map(member =>
              member.node.staffmember ? (
                <SingleTeamMember
                  key={member.node.staffmember}
                  number={data.AboutData.edges.indexOf(member)}
                  title={member.node.staffmembertitle}
                  name={member.node.staffmember}
                  creds={member.node.staffmembercreds}
                  email={member.node.staffmemberemail}
                  website={member.node.staffMemberWebsite}
                  details={member.node.staffmemberdescription}
                  picture={makeLink(member.node.picture)}
                />
              ) : null
            )}
          </InnerWrap>
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
    </>
  )
}

export const query = graphql`
  query AboutPageQuery {
    Meta: allGoogleSheetTeamBrazeeRow {
      edges {
        node {
          headerPicture
          sitetitle
        }
      }
    }
    AboutData: allGoogleSheetTeamBrazeeRow {
      edges {
        node {
          staffmember
          staffmembercreds
          staffmemberdescription
          staffmemberemail
          staffMemberWebsite
          staffmembertitle
          picture
          sitetitle
        }
      }
    }
  }
`

export default AboutPage
